.mySlides {
  display: none;
}

.prev, .next {
  cursor: pointer;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0 3px 3px 0;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  transition: all .6s;
  position: absolute;
  top: 50%;
}

.next {
  border-radius: 3px 0 0 3px;
  right: 0;
}

.prev:hover, .next:hover {
  background-color: #000c;
}

.text {
  color: #f2f2f2;
  text-align: center;
  width: 100%;
  padding: 8px 12px;
  font-size: 15px;
  position: absolute;
  bottom: 8px;
}

.numbertext {
  color: #f2f2f2;
  padding: 8px 12px;
  font-size: 12px;
  position: absolute;
  top: 0;
}

.dot {
  cursor: pointer;
  background-color: #bbb;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  transition: background-color .6s;
  display: inline-block;
}

.active, .dot:hover {
  background-color: #717171;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}

.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform .8s;
}

.img-hover-zoom:hover img {
  transform: scale(1.1);
}

.lds-hourglass {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-hourglass:after {
  content: " ";
  box-sizing: border-box;
  border: 32px solid #bdc3c7;
  border-color: #bdc3c7 #0000;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  animation: 1.2s infinite lds-hourglass;
  display: block;
}

@keyframes lds-hourglass {
  0% {
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: rotate(0);
  }

  50% {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: rotate(900deg);
  }

  100% {
    transform: rotate(1800deg);
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 8px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

.lds-ripple {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ripple div {
  opacity: 1;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite lds-ripple;
  position: absolute;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes lds-ripple {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
    top: 36px;
    left: 36px;
  }

  100% {
    opacity: 0;
    width: 72px;
    height: 72px;
    top: 0;
    left: 0;
  }
}

.iresponsive {
  width: auto;
  height: 100%;
}
/*# sourceMappingURL=index.2a57e484.css.map */
